import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./images/logo"

import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import '../scss/style.scss'

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#192128`,
      marginBottom: `1.45rem`,
      width: "100%"



    }}
  >


    <Navbar fixed="top" className="navbar-dark" expand="md" style={{ backgroundColor: "#192128" }}>

      <Navbar.Brand className="mr-auto headerLogo" style={{ maxWidth: "150px" }}>   <Link to="/" style={{ textDecoration: "none" }}> <Logo /> </Link>  </Navbar.Brand>
      <Nav className="mr-auto ml-auto d-md-none d-lg-none d-xl-none" >
        <Button variant="primary" size="sm" style={{ fontFamily: "Roboto", fontWeight: "bold" }}><Link to="/checkout/" style={{ textDecoration: "none", color: "#ffffff" }}> Order Request </Link></Button>
      </Nav>
      <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav" >
        <Nav className="ml-auto mr-auto">

          <Link to="/#pricing" className="m-2 nav-link" style={{ color: "#ffffff", fontFamily: "Roboto, sans-sarif, helvetica" }}>Pricing</Link>
          <Link to="/blog/" className="m-2 nav-link" style={{ color: "#ffffff", fontFamily: "Roboto, sans-sarif, helvetica" }}>Blog</Link>
          <Link to="#contact" className="m-2 nav-link" style={{ color: "#ffffff", fontFamily: "Roboto, sans-sarif, helvetica" }}>Contact Us</Link>

        </Nav>


      </Navbar.Collapse>

      <Nav className=" d-none d-sm-none d-md-block d-lg-block d-xl-block" >
        <Link to="/checkout/" style={{ textDecoration: "none", color: "#ffffff" }}> <Button variant="primary" style={{ fontFamily: "Roboto", fontWeight: "bold" }}> Order Request </Button> </Link>
      </Nav>



    </Navbar>


  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
